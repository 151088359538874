<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "User",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "User",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "User",
                    active: true,
                },
            ],
            // variable Page Table
            loadingTable: true,
            dataTable: [],
            pagingTable: [],
            showDataToTable: "",
            showDataFromTable: "",
            totalDataTable: "",
            currentTablePage: "",
            searchDataTable: "",

            // variable Import Excel
            showModalImportExcel: false,
            showButtonSaveImport: false,
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: "ENABLE", // untuk filtering data
            },
        };
    },
    mounted() {
        // Load data awal banget
        this.getDataTable();
    },
    methods: {
        // Method dari masing masing fungsi di dalam file ini

        getDataTable() {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        toPage(url) {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: url,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        searchTable() {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
                params: {
                    search: self.searchDataTable,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        changeStatusData(status, id) {
            let self = this;

            let text_alert = "";
            if (status == "ENABLE") {
                text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
            } else {
                text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
            }

            Swal.fire({
                title: "Perhatian",
                text: text_alert,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Iya`,
                denyButtonText: `Tidak`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "delete",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user",
                        data: {
                            id: id,
                            status: status,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        if (response_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "Success update data.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.searchTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
        downloadFile() {
            const link = document.createElement("a");
            link.href = "/template_user_import.xlsx";
            link.download = "/template_user_import.xlsx";
            link.click();
        },
        ImportExcel() {
            this.showModalImportExcel = true;
        },
        uploadFile() {
            let self = this;
            if ($("#importexcel")[0].files[0]) {
                if ($("#importexcel")[0].files[0].size < 2242880) {
                    $("#uploadLoading").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#importexcel")[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        console.log(response.data.data.path_file);
                        var path_file = response.data.data.path_file;
                        urlres += path_file;
                        $("#uploadLoading").html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>');
                        self.file_excel = path_file;
                        self.showButtonSaveImport = true;
                    });
                }
            }
        },
        StoreImportExcel() {
            let self = this;
            self.showModal = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading... menyimpan data",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/user/import",
                data: {
                    path_file: self.file_excel,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Import Data Berhasil",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        self.showModalImportExcel = false;
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.getDataTable();
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data;
                    Swal.close();
                });
        },
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">All Data User</div>
                            </div>

                            <div class="col-md-12">
                                <hr />
                                <div class="text-end">
                                    <button class="btn btn-sm btn-warning m-1" @click="ImportExcel"><i class="fas fa-file-excel"></i> Import Data</button>
                                    <router-link :to="{ name: 'add-user' }" class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Add User</router-link>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <br />
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">
                                        <!-- Untuk Filter Data -->
                                        &nbsp;
                                    </div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control" id="searchTable" v-on:change="searchTable" v-model="searchDataTable" placeholder="Search Data ..." />
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px">No</th>
                                                <th>CWID</th>
                                                <th>Nama</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th style="width: 150px">Created At</th>
                                                <th style="width: 150px">Updated At</th>
                                                <th style="width: 100px">Status</th>
                                                <th style="width: 175px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="8"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                            </tr>
                                            <tr v-for="(row_data, key_data) in dataTable" :key="key_data" v-else>
                                                <td class="text-center">
                                                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                                    <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                                                </td>
                                                <td>
                                                    {{ row_data.nip }}
                                                </td>
                                                <td>
                                                    {{ row_data.name }}
                                                </td>
                                                <td>
                                                    {{ row_data.email }}
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li v-for="(row, key) in JSON.parse(row_data.role_json)" :key="key">
                                                            {{ row.role }}
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td class="text-center">
                                                    {{ row_data.created_at ? fullDateTimeFormat(row_data.created_at) : "-" }}
                                                </td>
                                                <td class="text-center">
                                                    {{ row_data.updated_at ? fullDateTimeFormat(row_data.updated_at) : "-" }}
                                                </td>
                                                <td class="text-center">
                                                    <div v-if="row_data.status == 0">
                                                        <div class="badge badge-pill badge-soft-success font-size-12">ENABLE</div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="badge badge-pill badge-soft-danger font-size-12">DISABLE</div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div v-if="row_data.status == 0">
                                                        <router-link :to="{ name: 'edit-user', params: { idUser: row_data.id } }" class="btn btn-info btn-sm mb-1 w-100"><i class="fa fa-edit"></i> Edit</router-link>
                                                        <button type="button" class="btn btn-danger btn-sm mb-1 w-100" v-on:click="changeStatusData('DISABLE', row_data.id)"><i class="fa fa-times"></i> Disable</button>
                                                    </div>
                                                    <div v-else>
                                                        <router-link :to="{ name: 'edit-user', params: { idUser: row_data.id } }" class="btn btn-info btn-sm mb-1 w-100"><i class="fa fa-edit"></i> Edit</router-link>
                                                        <button type="button" class="btn btn-warning btn-sm mb-1 w-100" v-on:click="changeStatusData('ENABLE', row_data.id)"><i class="fas fa-undo"></i> Restore</button>
                                                        <button type="button" class="btn btn-danger btn-sm mb-1 w-100" v-on:click="changeStatusData('DELETE', row_data.id)"><i class="fas fa-trash"></i> Delete</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}</div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li v-for="(page, key_page) in pagingTable" v-bind:class="{ active: page.active }" :key="key_page" class="page-item">
                                                <div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    <!-- modal user import -->
    <b-modal v-model="showModalImportExcel" title="Import User" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="col-12">
            <div class="mb-3">
                <button class="btn btn-success" @click="downloadFile">
                    <i class="dripicons-article"> </i>
                    Download Template
                </button>
            </div>
        </div>
        <b-form class="p-2" @submit.prevent="StoreImportExcel">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <label for="Kode">Import File Excel</label>
                        <input class="form-control" type="file" id="importexcel" @change="uploadFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    </div>
                    <div class="respond-input-file float-left" id="uploadLoading"></div>
                </div>
            </div>
            <div class="text-end pt-5 mt-3">
                <b-button variant="light" @click="showModalImportExcel = false">Close</b-button>
                <b-button v-if="!showButtonSaveImport" variant="success" class="ms-1" disabled> <i class="bx bx-save"></i>Save </b-button>
                <b-button v-else type="submit" variant="success" class="ms-1">
                    <i class="bx bx-save"></i>
                    Save</b-button
                >
            </div>
        </b-form>
    </b-modal>
</template>
